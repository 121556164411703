import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import Img from 'gatsby-image'

const Blog = () => {
  const data = useStaticQuery(graphql`
    query BlogIndexQuery {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(blog)/" }
          frontmatter: { date: { ne: "null" } }
        }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            html
            id
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
              author
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <div className="blog">
        {data.allMarkdownRemark.edges
          .map(post => (
            <div key={post.node.id} className="post-item">
              <h3 className="post-title">{post.node.frontmatter.title}</h3>
              <small>
                Posted by {post.node.frontmatter.author} on{" "}
                {post.node.frontmatter.date}
              </small>
              <div
                dangerouslySetInnerHTML={{ __html: post.node.html }}
                className="post-text"
              ></div>
            </div>
          ))
          .slice(0, 10)}
      </div>
    </div>
  )
}

export default Blog
