import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Blog from '../components/Blog'
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { Container, Carousel } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const IndexPage = ({data}) => {
  
  return (
    <Layout>
      <Seo title="Home" />
        <Container fluid="l" className="banner p-0">
          <Carousel fade>
          {data.allFile.nodes.map(image => {
              return (
                  <Carousel.Item key={image.id} interval={5000} >
                      <GatsbyImage 
                          image={image.childImageSharp.gatsbyImageData} 
                          alt={image.base.split('-').join(' ').split('.')[0]}
                      />
                      <Carousel.Caption>
                          <p className="banner-text">{image.base.split('-').join(' ').split('.')[0]}</p>
                      </Carousel.Caption>
                  </Carousel.Item>
              )
              })}
          </Carousel>
        </Container>
        
      <section className="main-content main-home">
        <h1 className="main-title" id="main-title">Welkom</h1>
        <div className="column-left">
          <p>
            Een fotograaf wil zijn beelden delen en laten zien. Het zijn (nog) geen bijzondere of indrukwekkende beelden, 
            die in het geheugen van de kijker blijven hangen. Ik hoop me te kunnen ontwikkelen tot een fotograaf, die zulke 
            beelden maken kan. Om die reden volg ik de opleiding bij de Stichting Statief in Utrecht en neem ik deel aan de 
            autonome werkgroep (<a className="link" href="http://statief.nl" target="_blank" rel="noreferrer noopener">Fotoschool Statief - Professionele Fotografie Opleiding en
            Cursus</a>). En dat is ook het doel 
            van deze website: tonen wat ik heb gemaakt. Reacties en feedback zijn uiteraard welkom.
          </p>
        </div>
        <h1 className="main-title blog-title">Blog</h1>
        <div className="column-right">
          <Blog />
        </div>
      </section>
    </Layout>
  )
}


export default IndexPage

export const pageQuery = graphql `
    query {
        allFile(
            filter: {relativeDirectory: {eq: "bg"}}
            sort: {fields: base, order: DESC}
          ) {
            nodes {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 2.3)
              }
              base
              id
            }
          }
        }
      `